﻿@use "../common/bootstrap" as *;
@use "../common/scrollbar" as *;

.catalog {
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  flex-wrap: wrap;
  
  @include media-breakpoint-down(sm) {
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.catalog-wrapper {
  display: flex;
  
  @include media-breakpoint-down(sm) {
    flex-direction: column;
    justify-content: center;
  }

  .btn-load-more {
    background-color: $gray-600;
    min-height: 500px;
    height: 100%;
    border: 2px dashed;
    font-weight: 800;
    font-size: 2rem;
    border-radius: 0;
  }

  .text-no-data {
    @extend .btn-load-more;

    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    text-align: center;
    vertical-align: middle;
    color: #fff;
    cursor: text;;
  }
}

.search-categories-container {
  align-self: flex-start;
  position: sticky;
  width: 431px;
  top: 0;
  border: 0;
  border-radius: unset;
  box-shadow: none;
  background-color: #f8f9fa;
  margin-right: 15px;
  padding: 8px 15px 1rem 15px;
  
  div[tabindex='0'] {
    outline: none;
    
    &>.form-control {
      margin-bottom: 8px;
    }
  }
  
  i {
    font-size: 18px;
    width: 15px;
  }

  @include media-breakpoint-down(sm) {
    margin-right: 0;
  }

  @include media-breakpoint-only(md) {
    margin-right: 8px;
  }
  
  .list-group {
    border-radius: unset;
  }
  
  .list-group-item {
    border: none;
    padding: 0.4rem 1.25rem;
    cursor: pointer;
    
    &:hover {
      box-shadow: 0 0 1px rgb(221, 102, 15);
      z-index: 1;
    }
    
    &:not(.active) {
      background-color: #f8f9fa;
    }
    
    &.active {
      background-color: $orange;
    }
  }

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    width: 100%;
    position: unset;
    top: unset;
  }
}

.load-more-container {
  padding-bottom: 1rem;

  .btn-primary {
    padding-left: 7rem;
    padding-right: 7rem;
  }
}