@use "../common/bootstrap" as *;

.distributor-list-item {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  padding: 5px 0;
  border-bottom: 1px solid $gray-300;

  &:nth-of-type(2n) {
    background-color: $gray-200;
  }

  &:nth-of-type(2n+1) {
    background-color: $gray-100;
  }

  .distributor-list-value {
    width: 100px;
    overflow-wrap: anywhere;
    word-break: break-word;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 5px 10px;

    @include media-breakpoint-down(sm) {
      width: 75px;
    }

    &.name {
      flex-grow: 0;
      width: 300px;

      @include media-breakpoint-up(xl) {
        width: 500px;
      }

      @include media-breakpoint-down(sm) {
        width: 150px;
      }
    }

    &.warehouses {
      width: auto;
      flex-grow: 0;
      flex-direction: row;

      @include media-breakpoint-up(xl) {
        min-width: 350px !important;
        max-width: 350px;
      }

      @include media-breakpoint-between(md, lg) {
        max-width: 300px;
      }

      @include media-breakpoint-down(md) {
        order: 1;
        max-width: 100%;
      }

      @include media-breakpoint-up(sm) {
        min-width: 300px;
      }

      .badge {
        margin: 5px;
      }
    }

    &.account-number {
    }

    &.supplier-rep {
    }
  }
}