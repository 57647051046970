@use "../common/bootstrap" as *;

.location-list-value {
  padding: 5px 10px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.name {
    align-items: flex-start;
    flex-grow: 0;
    width: 300px;

    @include media-breakpoint-down(sm) {
      width: 135px;
    }
  }

  &.status {
    align-items: flex-start;
    flex-grow: 0;
    min-width: 80px;
  }

  &.edited {
    align-items: flex-end;
    flex-grow: 0;
    min-width: 70px;
  }

  &.actions {
    align-items: flex-end;
    max-width: 250px;
    flex-grow: 0;

    @include media-breakpoint-down(lg) {
      flex-grow: 1;
    }

    @include media-breakpoint-down(xs) {
      max-width: 100%;
    }
  }
}