﻿@use "../common/bootstrap" as *;
@use "../common/images" as *;

.order-container {
  .value {
    color: $orange;
  }
  
  .form-label {
    font-weight: $font-weight-bold;
  }
  
  padding: 0;
  
  form {
    @extend .container;
  }
}

.order-empty-notice:not(hidden) {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.order-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.order-heading {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  
  .left {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 1;
    align-self: start;
  }
  
  .right {
    margin-top: 15px;
    text-align: end;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex-grow: 1;
    align-self: flex-end;
    
    @include media-breakpoint-down(sm) {
      text-align: start;
      align-self: flex-start;
      align-items: flex-start;
      margin-bottom: 15px;
    }
  }
  
  .order-static-field-group {
    display: flex;
    flex-direction: column;
  }
  
  .value {
    font-weight: 600;
  }
}

.order-edit-fields {
  flex-grow: 1;
  align-self: flex-end;
}

.order-subtotals {
  flex-grow: 1;
  text-align: end;
  align-self: flex-end;
  @include font-size(18px);
}

.order-pdf {
  color: #FF2116;
  font-size: 20px;
  margin-left: 5px;
}

.order-warehouse-section {
  margin-top: 25px;
  margin-bottom: 10px;
}

.order-warehouse-header {
  display: flex;
  flex-direction: row;
  border-bottom: 2px solid $border-color;
}

.order-warehouse-name {
  @include font-size(18px);
}

.order-notice {
  color: $red;
}

.order-warehouse-fields {
  display: flex;
  flex-direction: column;
  
  .form-group {
    flex-grow: 1;
  }
  
  @include media-breakpoint-up(md) {
    flex-direction: row;
    
    .form-group:not(:last-of-type) {
      margin-right: 15px;
    }
  }
}

.order-separator {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  .order-separator-text {
    padding: 10px 20px 5px 20px;
    display: flex;
    background-color: $white;
    border-left: 1px solid $border-color;
    border-right: 1px solid $border-color;

    h2 {
      font-size: 1.3rem;
      color: $orange;
      font-weight: $font-weight-bold;
    }
  }
  
  b {
    flex-grow: 1;
    border: 1px solid $border-color;
    height: 0;
  }
}

.order-action-buttons {
  position: sticky;
  bottom: 0;
  background-color: #f7f7f7;
  display: flex;
  justify-content: space-evenly;
  
  button {
    width: 150px;
    
    @include media-breakpoint-down(sm) {
      width: 120px;
    }
  }
}

.order-product-list {
  display: flex;
  flex-direction: column;
  
  .order-product-item {
    
  }
  
  .order-product-item-line {
    margin-bottom: 25px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    
    .discount-buttons {
      padding-top: 33px;

      @include media-breakpoint-up(md) {
        min-width: 270px;
        min-height: 45px;
      }
    }
    
    @include media-breakpoint-down(md) {
      .discount-buttons {
        padding-top: 5px;
      }
    }
    
    &>div {      
      margin-top: 20px;
    }
    
    .order-field-font {
      @include media-breakpoint-up(lg) {
        font-size: 16px;
      }

      @include media-breakpoint-only(md) {
        font-size: 13px;
      }

      @include media-breakpoint-down(sm) {
        font-size: 16px;
      }
    }
    
    .order-product-details {
      @include media-breakpoint-up(md) {
        padding-left: 15px;
      }
      
      @include media-breakpoint-down(sm) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        
        &>div {
          padding: 7px;
        }
      }
    }
    
    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }

    .product-image {
      cursor: pointer;
      margin-top: 0;
      
      @include media-breakpoint-up(lg) {
        flex-basis: 20%;
      }

      @include media-breakpoint-only(md) {
        flex-basis: 40%;
      }

      @include media-breakpoint-down(sm) {
        width: 100%;
      }

      @include media-breakpoint-up(md) {
        .image-container {
          @include image-container(300px / 300px);
        }
      }

      @include media-breakpoint-down(sm) {
        flex-basis: unset;

        .image-container {
          @include image-container(300px / 350px);
        }
      }
    }
    
    .order-product-line-wrapper {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      flex-grow: 1;
      flex-wrap: wrap;
      
      @include media-breakpoint-down(sm) {
        width: 100%;
        margin-left: 0;
      }
      
      &>div {
        @include media-breakpoint-up(md) {
          margin-left: 15px;
        }
      }
    }
    
    .order-product-price-wrapper {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      flex-grow: 1;
      flex-wrap: wrap;

      @include media-breakpoint-down(sm) {
        width: 100%;
        margin-left: 0;
      }
    }
  }
}

.order-warehouse-header {
  padding-bottom: 10px;
}

.order-list-container {
  .value {
    color: $orange;
  }

  .form-label {
    font-weight: $font-weight-bold;
  }

  padding: 0;

  form {
    @extend .container;
  }
  
  .order-list-items {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include media-breakpoint-down(lg) {
      width: 100%;
    }
  }
  
  .order-list-item {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 5px 0;
    border-bottom: 1px solid $gray-300;
  }

  .order-list-item:nth-of-type(2n+1) {
    background-color: $gray-100;
  }

  .order-list-item:nth-of-type(2n) {
    background-color: $gray-200;
  }
  
  .order-list-value {
    padding: 5px 10px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    &.actions {
      align-items: flex-end;
      width: 250px;
      flex-grow: 0;
      
      @include media-breakpoint-down(lg) {
        flex-grow: 1;
      }
    }
    
    &.submitted {
      flex-grow: 0;
      width: 180px;
      
      @include media-breakpoint-down(sm) {
        width: 100px;
      }
    }
    
    &.status {
      flex-grow: 0;
      width: 150px;
    }
    
    &.created {
      flex-grow: 0;
      width: 180px;

      @include media-breakpoint-down(sm) {
        width: 100px;
      }
    }

    &.id {
      flex-grow: 0;
      width: 120px;

      @include media-breakpoint-down(sm) {
        width: 100px;
      }
    }
  }
  
  .btn-load-more {
    background-color: $gray-600;
    height: 100%;
    width: 100%;
    border: 2px dashed;
    font-weight: 800;
    font-size: 2rem;
    border-radius: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
    .baseline-text {
      padding-right: 10px;
    }
  }
  
  .order-list-filter {
    padding: 20px 0;
    
    form {
      display: flex;
      flex-direction: row;
      
      @include media-breakpoint-up(md) {
        .form-group:not(:first-of-type) {
          margin-left: 20px;
        }
      }
      
      @include media-breakpoint-down(sm) {
        flex-direction: column;
        
        .form-group {
          margin-left: 0;
        }
      }
      
      .form-group {
        flex-grow: 1;
        margin-top: 10px;
      }
    }
  }
}