@use "../common/bootstrap" as *;

.user-list-value {
  padding: 5px 10px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.fullName {
    flex-grow: 0;
    width: 220px;

    @include media-breakpoint-down(sm) {
      width: 100px;
    }
  }

  &.email {
    flex-grow: 0;
    width: 250px;

    @include media-breakpoint-down(sm) {
      width: 150px;
    }
  }

  &.status {
    flex-grow: 0;
    width: 100px;
  }

  &.lastLogin {
    flex-grow: 0;
    width: 180px;

    @include media-breakpoint-down(sm) {
      width: 100px;
    }
  }

  &.actions {
    align-items: flex-end;
    width: 300px;
    flex-grow: 0;

    @include media-breakpoint-down(lg) {
      flex-grow: 1;
    }
  }
}