@use "../common/bootstrap" as *;
@use "../common/scrollbar" as *;
@use "../common/images" as *;

.card-image {
  align-self: center;
  padding: 5px;
  width: 100%;

  @include media-breakpoint-between(md, null) {
    flex-basis: 35%;
    width: auto;
    min-width: 300px;
    align-self: auto;
    .image-container {
      @include image-container(400px / 300px);
    }
  }
  
  .image-container {
    img {
      max-height: 400px;
      object-fit: contain;
    }
  }
}

.product-card-body {
  @include media-breakpoint-only(lg) {
    padding: 1.5rem;

    .list-group-item {
      padding: 0.75rem;
    }
  }
  @include media-breakpoint-only(md) {
    padding: 1.25rem;

    .list-group-item {
      padding: 0.5rem;
    }
  }
  @include media-breakpoint-only(sm) {
    padding: 0.75rem;

    .list-group-item {
      padding: 0.25rem;
    }
  }
  @include media-breakpoint-down(xs) {
    padding: 0.5rem;

    .list-group-item {
      padding: 0.25rem;
    }
  }
}

.card-control-buttons {
  padding-top: 10px;

  .input-group {
    .form-control, .input-group-append {
      width: 50%;
    }

    .card-button {
      width: 100%;
    }
  }
}

.product-detail-card-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .left {
    .card-button {
      width: 145px;
      max-width: 145px;
    }
  }

  .right {
    .input-group {
      width: 290px;
      max-width: 290px;
    }

    .card-qty {
      max-width: unset;
      width: 50%;
    }

    .input-group-append {
      width: 50%;
    }
  }

  @include media-breakpoint-down(sm) {
    position: sticky;
    bottom: 0;
    background-color: #f7f7f7;
    flex-direction: column;
    
    .form-control {
      height: auto;
    }

    .left {
      margin-top: 10px;
      width: 100%;
      order: 1;

      .card-button {
        width: 100%;
        max-width: unset;
      }
    }

    .right {
      width: 100%;

      .input-group {
        width: 100%;
        max-width: unset;

        .card-button {
          width: 100%;
        }
      }
    }
  }

  .left {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: flex-start;
  }

  .right {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: flex-end;
  }
}

.card-flex {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: space-between;

  @include media-breakpoint-between(md, null) {
    flex-direction: row;
  }
}

.card-content-scrollable {
  @include media-breakpoint-between(md, null) {
    @include thin();
    overflow-y: auto;
    max-height: 600px;
    padding-right: 10px;
  }
}

.card-content {
  @include media-breakpoint-between(md, null) {
    flex-basis: 65%;
  }
  display: flex;
  flex-direction: column;
}

.card-short-description {
  margin-bottom: 15px;
}

.card-content-extended {
  @extend .pt-4;
  border-top: 2px solid #d6d8db;
  @include media-breakpoint-between(md, null) {
    padding-left: 10px;
  }
}

.input-group {
  margin-right: 0;
  margin-left: auto;
  justify-content: flex-end;
}

.card-brand {
  display: flex;
  flex-grow: 2;
  justify-content: flex-start;
}

.card-price {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  font-size: 1.5rem;
}

.card-price-shortcut {
  justify-content: flex-start;
  color: red;
}

.discount-buttons {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding-top: 10px;
  padding-bottom: 10px;
}

.discount-button:not(:first-of-type) {
  @include media-breakpoint-up(xl) {
    margin-left: 12px;
  }

  @include media-breakpoint-only(lg) {
    margin-left: 10px;
  }

  @include media-breakpoint-only(md) {
    margin-left: 8px;
  }

  @include media-breakpoint-only(sm) {
    margin-left: 6px;
  }

  @include media-breakpoint-down(xs) {
    margin-left: 4px;
  }
}

.card-shortcut {
  flex-grow: 1;
  max-width: 22rem;
  min-width: 252px;

  @include media-breakpoint-down(sm) {
    flex-grow: unset;
    max-width: none !important;
    width: 100%;
  }
  
  .discount-buttons {
    min-height: 50px;
  }
}

.card-shortcut-image {
  padding: 10px;

  @include media-breakpoint-between(md, null) {
    .image-container {
      @include image-container(240px / 350px);
    }
  }

  .image-container {
    @include image-container(300px / 350px);
  }
}

.card-button-details-shortcut {
  font-size: 1.3rem;
  padding: 0;
}

h3.card-title {
  font-size: 1.1rem;
}

.card-shortcut-info .fa {
  width: 30px;
}

@include media-breakpoint-between(null, lg) {
  .modal-dialog {
    max-width: none !important;
    margin: 0 auto !important;
  }

  .modal-container {
    padding: 0 !important;
  }
  
  .modal-content {
    border: 0;
    border-radius: unset;
  }
}