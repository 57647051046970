﻿@use "../common/bootstrap" as *;

.content-container {
  padding-top: 25px;
  padding-bottom: 25px;
  min-height: 50vh;
  
  @include media-breakpoint-down(sm) {
    min-height: auto;
  }
}