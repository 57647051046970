@mixin reset {
  scrollbar-width: unset;

  &::-webkit-scrollbar {}
  &::-webkit-scrollbar-button {}
  &::-webkit-scrollbar-thumb {}
  &::-webkit-scrollbar-thumb:hover {}
  &::-webkit-scrollbar-track {}
  &::-webkit-scrollbar-track-piece {}
  &::-webkit-scrollbar-corner {}
  &::-webkit-resizer {}
}

@mixin thin {
  @include reset;

  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 0.5rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.125);
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.25);
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0.5rem rgba(0, 0, 0, 0.125);
  }
}

@mixin hide {
  @include reset;

  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin reset-scrollbar {
  scrollbar-width: unset;

  &::-webkit-scrollbar {}
  &::-webkit-scrollbar-button {}
  &::-webkit-scrollbar-thumb {}
  &::-webkit-scrollbar-thumb:hover {}
  &::-webkit-scrollbar-track {}
  &::-webkit-scrollbar-track-piece {}
  &::-webkit-scrollbar-corner {}
  &::-webkit-resizer {}
}

@mixin hide-scrollbar {
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}