﻿@use "bootstrap" as *;

body {
  background-color: $light;
}

.border-top-fix::before {
  height: 1px;
  content: " ";
  display: flex;
  width: 100%;
  background-color: #dee2e6;
}

#root {
  background-color: $orange;
  
  header {
    background-color: $light;

    .container {
      background-color: $light;
    }
  }

  .container {
    background-color: $white;
  }

  footer {
    background-color: $light;

    .container {
      background-color: $light;
    }
    
    .navbar {
      padding: 1rem 0;
    }
  }
}

.search-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100%;
}

.bottom-form-container {
  width: 100%;
}

.navbar-nav {
  @include media-breakpoint-down(sm) {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

.loading-indicator {
  width: 100%;
}

.search-bar {
  margin-top: 15px;

  @include media-breakpoint-up(md) {
    margin-right: 25px;
  }
}

.layout-navigation {
  min-width: 282px;
  .container {
    max-width: none;

    @include media-breakpoint-down(sm) {
      .search-bar {
        order: 2;
      }

      .navbar-collapse {
        order: 1;
      }

      .search-bar-details {
        order: 3;
      }
    }
  }
}

.search-bar-details {
  width: 100%;
  margin-top: 15px;

  .bottom-form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;

    .form-group {
      flex-grow: 1;

      @include media-breakpoint-down(sm) {
        width: 100%;
      }

      &:not(:last-of-type) {
        @include media-breakpoint-up(md) {
          margin-right: 25px;
        }
      }
    }

    select, input {
      width: 100%;

      @include media-breakpoint-down(md) {
        margin-left: auto;
        margin-right: auto;
      }
    }

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      justify-content: center;
    }
  }

  .deals-bottom-form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      justify-content: center;
    }

    @include media-breakpoint-up(md) {
      label:not(:last-of-type) {
        margin-right: 25px;
      }
    }
    
    .form-check-label {
      font-size: 1rem;
      font-weight: 400;
      margin-left: 0.25rem;
    }
    
    .form-check-input {
      width: 1rem;
      height: 1rem;
    }
  }
}

.list-group.list-group-even-odd {
  .list-group-item:nth-of-type(2n-1) {
    @extend .list-group-item-secondary;
  }
}

.badge-lg {
  font-size: 1.3rem;
}

.form-control-md {
  width: 180px;
  max-width: 180px;

  @include media-breakpoint-down(sm) {
    width: 100%;
    max-width: unset;
  }
}

.form-control-sm {
  width: 150px;
  max-width: 150px;

  @include media-breakpoint-down(sm) {
    width: 90px;
    max-width: unset;
  }
}

@mixin reset-button() {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;

  background: transparent;

  text-align: inherit;

  line-height: normal;

  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  -webkit-appearance: none;

  border-radius: 0;

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}

.button-as-link {
  color: $info;
}

.button-reset {
  @include reset-button();
}

.btn-large {
  width: 50%;
  font-size: x-large;
}

.confirmation-popup-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .card-button {
    width: 145px;
    max-width: 145px;
  }

  @include media-breakpoint-down(sm) {
    position: sticky;
    bottom: 0;
    background-color: #f7f7f7;
    flex-direction: column;

    .card-button {
      width: 100%;
      max-width: unset;
    }

    .left {
      margin-top: 10px;
      width: 100%;
      order: 1;
    }

    .right {
      width: 100%;
    }
  }

  .left {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: flex-start;
  }

  .right {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: flex-end;
  }
}

.no-border-radius {
  border-radius: 0 !important;
  border: 0 !important;
}

.scroll-to-top {
  cursor: pointer;
  z-index: 999;
  position: fixed;
  bottom: 0.5rem;
  right: 0.5rem;
  opacity: 0;
  filter: alpha(opacity=0); //opacity(0%)
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -ms-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;

  &.visible {
    opacity: 0.3;
    filter: alpha(opacity=30); //opacity(30%)

    &:hover {
      opacity: 1;
      filter: alpha(opacity=100); //opacity(100%)
    }
  }
}