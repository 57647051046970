@use "../common/bootstrap" as *;
@use "sass:color";

.location-menu-section {
  flex-direction: row;
  flex-grow: 1;
  flex-wrap: wrap;
  justify-content: space-evenly;

  .nav-item .text-orange.nav-link {
    @extend .text-orange;

    font-weight: 600;

    padding-left: 7px !important;
    padding-right: 7px !important;

    &.active {
      @extend .shadow;
      @extend .rounded;
    }

    &.disabled {
      color: color.adjust($orange, $lightness: 20%);
    }
  }
}