@use "bootstrap" as *;
@use "fonts";

@mixin open-link {
  @extend .badge;
  @extend .badge-secondary;

  opacity: 0.75;
  position: absolute;
  z-index: 1020;

  .label {
    @extend .float-left;
    @extend .d-none;
    @extend .d-md-block;
  }

  .icon {
    @extend .fas;
    @extend .fa-external-link-square-alt;
  }
}
