﻿@use "../common/bootstrap" as *;

.registration-form-container {
  padding: 25px 15px;
  min-height: 50vh;

  @include media-breakpoint-down(sm) {
    min-height: auto;
    padding: 25px 0;
  }

  form {
    @extend .container;

    @include media-breakpoint-down(sm) {
      padding: 0;
    }
  }
}

.registration-form-wrapper {
  margin: 0 auto;

  .buyer-description {
    text-align: center;

    @extend .text-muted;
  }

  .account-numbers-error {
    color: $red;
  }
}

.registration-form-separator {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  .registration-form-separator-text {
    padding: 10px 20px 5px 20px;
    display: flex;
    background-color: $white;
    border-left: 1px solid $border-color;
    border-right: 1px solid $border-color;

    h2 {
      font-size: 1.3rem;
      color: $orange;
      font-weight: $font-weight-bold;
    }
  }

  b {
    flex-grow: 1;
    border: 1px solid $border-color;
    height: 0;
  }
}

.registration-form-block {
  display: flex;
  flex-direction: column;
  max-width: 420px;
}

.registration-form-action-buttons {
  bottom: 0;
  background-color: #f7f7f7;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;

  div {
    margin: 5px;
    align-self: center;
  }

  button {
    min-width: 150px;

    @include media-breakpoint-down(sm) {
      min-width: 120px;
    }
  }
}

.alert.alert-info {
  background-color: unset;
  border-color: unset;
  border: unset;
}