﻿@use "bootstrap" as *;

@mixin image-container($aspectRatioHW, $objectFit: contain) {
  text-align: center;
  height: 0;
  overflow: hidden;
  padding-top: $aspectRatioHW * 100%;
  background: white;
  position: relative;
  max-height: unset;
  max-width: unset;

  img, .image {
    object-fit: $objectFit;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: unset;
    max-width: unset;
  }
}