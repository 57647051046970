﻿@use "bootstrap" as *;
@use "badge";
@use "images" as *;

@forward "node_modules/swiper/swiper" with (
  $themeColor: $gray-500
);

@use "node_modules/swiper/components/lazy/lazy";
@use "node_modules/swiper/components/navigation/navigation";
@use "node_modules/swiper/components/pagination/pagination";
@use "node_modules/swiper/components/scrollbar/scrollbar";
@use "node_modules/swiper/components/thumbs/thumbs";
@use "node_modules/swiper/components/zoom/zoom";

.carousel-control-prev:hover {
  @extend .shadow;

  background: rgba(255,255,255, 0.2);
}

.carousel-control-next:hover {
  @extend .shadow;

  background: rgba(255,255,255, 0.2);
}

.carousel-link-badge {
  right: 17%;
  bottom: 1%;

  @include badge.open-link;

  @include media-breakpoint-up(xl) {
    font-size: 150%;
  }

  @include media-breakpoint-between(md, lg) {
    font-size: 100%;
  }

  @include media-breakpoint-down(sm) {
    font-size: 75%;
  }
}

/**
 * @comment a private member is starting its name with either - or _.
 * according to @link [official docs](https://sass-lang.com/documentation/at-rules/use#private-members)
 */
@function _generate-multiple-siblings($n, $prepend, $sibling, $lastPrependPart: "") {
  $result: "";

  @for $i from 1 through $n {
    @if ($i == $n) {
      $result: $result + $prepend + $lastPrependPart;
    } @else {
      $result: $result + $prepend;
    }
    @for $j from 1 through $i {
      $result: $result + " + " + $sibling;
    }
    @if ($i != $n) {
      $result: $result + ",";
    }
  }
  
  @return $result;
}

@mixin multiple-columns($n, $pad: 25px) {
  @if ($n > 1) {
    --multiple: #{$n};

    .carousel-item {
      flex-basis: calc(100% / #{$n});
      transition: none;

      &.carousel-item-right,
      &.active.carousel-item-right,
      #{_generate-multiple-siblings($n - 1, "&.active.carousel-item-right", ".carousel-item")} {
        left: calc(-100% / #{$n});
      }

      &.active,
      #{_generate-multiple-siblings($n, "&.active", ".carousel-item", ":not(.carousel-item-right)")} {
        display: block;
        padding-right: #{$pad};
      }

      &.carousel-item-right {
        padding-right: #{$pad};
        margin-right: 0;
      }

      &.active,
      #{_generate-multiple-siblings($n - 1, "&.active", ".carousel-item", ":not(.carousel-item-right)")} {
        margin-right: 0;
      }

      &.carousel-item-prev,
      &.carousel-item-next,
      &.active.carousel-item-left,
      &.active.carousel-item-right {
        transform: translate3d(0, 0, 0);
      }

      &.active.carousel-item-left,
      #{_generate-multiple-siblings($n, "&.active.carousel-item-left", ".carousel-item")} {
        transform: translate3d(-100%, 0, 0);
        transition: transform .6s ease-in-out;
      }

      &.carousel-item-right.carousel-item-prev,
      &.active.carousel-item-right,
      #{_generate-multiple-siblings($n - 1, "&.active.carousel-item-right", ".carousel-item")} {
        transform: translate3d(100%, 0, 0);
        transition: transform .6s ease-in-out;
      }
    }

    .carousel-control-prev, .carousel-control-next {
      width: 5%;
    }

    .carousel-control-next {
      right: #{$pad};
    }
  }
}

.carousel-with-preview-thumbs {
 
  .carousel-indicators {
    position: static;
    margin-top: 20px;
  }

  .carousel-indicators li {
    width: 100px;
    height: 100px;
  }

  .carousel-indicators li img {
    display: block;
    opacity: 0.5;
  }

  .carousel-indicators li.active img {
    opacity: 1;
  }

  .carousel-indicators li:hover img {
    opacity: 0.75;
  }
}

.zoom-modal-container {
  max-height: 100vh;
  
  .modal-dialog {
    min-height: unset !important;
  }
  
  .exit-button {
    position: absolute;
    top: 0.5rem;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    opacity: 0.6;
    z-index: 10;
    border-radius: 30px;

    @include media-breakpoint-up(xl) {
      width: 48px;
    }

    @include media-breakpoint-between(md, lg) {
      width: 48px;
    }

    @include media-breakpoint-down(sm) {
      width: 42px;
    }
  }
  
  .swiper-slide {
    height: auto;
  }
  
  .img-fluid {
    height: 100%;
    max-height: calc(100vh);
  }
  
  .zoom-image-container {
    min-height: 50vh;
    max-height: 100vh;
    
    .swiper-container {
      min-height: 50vh;
      max-height: 100vh;
    }
  }
}