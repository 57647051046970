﻿@use "bootstrap" as *;
@use "sass:color";

a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.text-orange {
  color: $orange;
}

.navbar-nav .nav-item .text-orange.nav-link {
  @extend .text-orange;

  font-weight: 600;

  padding-left: 7px !important;
  padding-right: 7px !important;

  &.active {
    @extend .shadow;
    @extend .rounded;
  }

  &.disabled {
    color: color.adjust($orange, $lightness: 20%);
  }
}

.left-menu-section {
  flex-grow: 1;
  flex-wrap: wrap;
}

.right-menu-section {
  .loading-indicator {
    width: auto;
  }
  
  flex-wrap: wrap;
  justify-content: flex-end;
  &>.nav-item>.dropdown-menu {
    left: -50px;
  }

  .menu-locations {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
  }

  #account-menu {
    @extend .text-orange;

    font-weight: 600;
  }

  .text-orange.dropdown-item {
    @extend .text-orange;

    background-color: transparent;
    font-weight: 600;

    &.active, &:active {
      @extend .shadow;
      @extend .rounded;
    }

    &.disabled {
      color: color.adjust($orange, $lightness: 20%);
    }
  }

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.menu-account-mobile {
  @include media-breakpoint-up(md) {
    display: none;
  }
}

.menu-locations-mobile {
  display:flex;
  align-items: center;

  @include media-breakpoint-up(md) {
    display: none;
  }
}

.select {
  width: auto;
  padding: unset;
  min-width: 250px;
  z-index: 10;

  &.multi-select {
    height: auto;
  }
}

.flex-grow {
  flex-grow: 1;
}