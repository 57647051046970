﻿@use "../common/bootstrap" as *;
@use "../common/images" as *;

.home-page {
  display: flex;
  flex-direction: column;
  padding-top: 15px;

  .card-shortcut {
    flex-grow: 1;
    max-width: 200px;
    min-width: 200px;
  }

  h3.card-title {
    font-size: 1rem;
  }
  
  .widget-card-body {
    padding: 0.5rem;
    text-align: center;
  }
  
  .widget-card-footer {
    padding: 0 0.75rem;
  }
  
  .home-page-slider {
    .carousel-item {
      @include image-container(445px / 1110px, cover);
    }
    margin-bottom: 25px;
  }
}

.content-widget-group {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.content-widget-header-text {
  padding: 10px 20px 5px 20px;
  display: flex;
  background-color: $white;
  border: 3px solid $red;

  i {
    font-size: 2.3rem;
    line-height: 1.3;
    margin-right: 10px;
  }
  
  h2 {
    font-size: 2.3rem;
  }
}

.widget-card-shortcut {
  @include media-breakpoint-down(sm) {
    max-width: 725px;
  }
  
  .card-shortcut-image {
    text-align: center;
  }
}

.content-widget-header {
  color: $red;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  
  b {
    flex-grow: 1;
    height: 3px;
    background-color: $red;
    
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
}

@include media-breakpoint-down(sm) {
  .content-widget-header-text {
    border: 2px solid $red;

    i {
      font-size: 1.5rem;
    }

    h2 {
      font-size: 1.5rem;
    }
  }
}

.content-widget-items {
  padding: 1rem 0;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem 1rem;
  justify-content: center;
  
  @include media-breakpoint-down(sm) {
    grid-template-columns: minmax(250px, 1fr);
  }
}

.badge-spacer {
  height: calc(0.5em + 1.3rem);
}